import React, { useState } from "react";
import "./Header.css";
import img from "../img/logo.webp";
import { FaBars } from "react-icons/fa";

export default function Header() {
  const [header, setHeader] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  const handleSidebar = () => {
    setSidebar((prevState) => !prevState);
  };

  const changeHeader = () => {
    if (window.scrollY >= 30) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  window.addEventListener("scroll", changeHeader);
  return (
    <div className={header ? "header active" : "header"}>
      <div
        className={sidebar ? "backdrop backdrop-open" : "backdrop"}
        onClick={handleSidebar}
      ></div>
      <div>
        <img
          className="logo"
          src={img}
          alt="кейдев, KeyDev, КейДев, Разработка сайтов под ключ,  Разработка CRM систем, Создание уникального дизайна сайтов,
          Адаптивный пользовательский интерфейс, Интеграция CMS (систем управления контентом), Электронная коммерция (e-commerce),
          Оптимизация для поисковых систем (SEO), Безопасность и защита данных пользователей, Управление отношениями с клиентами (CRM),
          Интеграция CRM с существующими системами, Пользовательские панели управления и аналитика, IT-сопровождение,
          Мониторинг системы и устранение проблем, Обновление и модернизация системы, Резервное копирование данных,
          Интеграция с сервисом ТУНДУК, Получение и обработка данных через ТУНДУК, Безопасность данных клиентов" 
        />
      </div>
      <div className="menu">
        <a href="#section">Главная</a>
        <a href="#services">Услуги</a>
        <a href="#team">О Нас</a>
        <a href="#contacts">Контакты</a>
      </div>
      <div className="burger-menu">
        <a href="#" className="burger-icon" onClick={handleSidebar} aria-label="кейдев, KeyDev, КейДев, Разработка сайтов под ключ,  Разработка CRM систем, Создание уникального дизайна сайтов,
    Адаптивный пользовательский интерфейс, Интеграция CMS (систем управления контентом), Электронная коммерция (e-commerce),
    Оптимизация для поисковых систем (SEO), Безопасность и защита данных пользователей, Управление отношениями с клиентами (CRM),
    Интеграция CRM с существующими системами, Пользовательские панели управления и аналитика, IT-сопровождение,
    Мониторинг системы и устранение проблем, Обновление и модернизация системы, Резервное копирование данных,
    Интеграция с сервисом ТУНДУК, Получение и обработка данных через ТУНДУК, Безопасность данных клиентов" >
          <FaBars />
        </a>
        <div className={sidebar ? "side-bar side-bar-open" : "side-bar"}>
          <a href="#section">Главная</a>
          <a href="#services">Услуги</a>
          <a href="#team">О Нас</a>
          <a href="#contacts">Контакты</a>
        </div>
      </div>
    </div>
  );
}
