import React, { useContext } from 'react'
import './Services.css'
import axios from 'axios';
import * as Icons from "react-icons/fa";
import { AuthContext } from '../../context/auth';


// const baseURL = 'http://127.0.0.1:8000/api/services/'
const baseURL = 'https://keydev.kg/api/services/'

const DynamicFaIcon = ({ name }) => {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    return <Icons.FaDev/>;
  }
    return <IconComponent />;
};

export default function Services() {
  const [services, setServices] = React.useState(null);
  const {token} = useContext(AuthContext)

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Token ${token}`,
        };
        const response = await axios.get(baseURL, { headers });
        setServices(response.data);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
      }
    };
  
    if (token) {
      fetchData();
    }
  }, [token]);


  return (
    <div className='services' id='services'>
        <h1 className='section-title'>НАШИ УСЛУГИ</h1>
        <h3 className='section-subtitle'>Мы предоставляем следующие виды услуг:</h3>
        {/* <p className='services-description section-subtitle-color'>Разработка сайтов под ключ: </p>
        <p className='services-description'>Разработка сайтов различной сложности и функциональности с нуля. 
Создание уникального дизайна и адаптивного пользовательского интерфейса. 
Интеграция необходимых функций, таких как системы управления контентом (CMS), электронная коммерция (e-commerce), аналитика и другие инструменты. 
Оптимизация для поисковых систем (SEO) для повышения видимости в поисковых запросах. </p>
        <p className='services-description section-subtitle-color'>Разработка CRM систем: </p>
        <p className='services-description'>Полный цикл разработки CRM-систем под ваши индивидуальные потребности. 
Создание системы управления отношениями с клиентами (CRM) для эффективного управления продажами, маркетингом и обслуживанием клиентов. 
Интеграция CRM с существующими системами для обеспечения единого источника данных о клиентах и улучшения общего опыта взаимодействия с клиентами. 
Разработка пользовательских панелей управления, отчетов и аналитики для эффективного мониторинга деятельности клиентов. </p>
        <p className='services-description section-subtitle-color'>IT - сопровождение: </p>
        <p className='services-description'>Предоставление непрерывной поддержки и обслуживания информационных технологий для бесперебойной работы вашего бизнеса. 
Мониторинг системы и проактивное устранение проблем для предотвращения неполадок и сбоев. 
Обновление и модернизация системы в соответствии с последними технологическими трендами и потребностями вашего бизнеса. 
Резервное копирование данных и обеспечение безопасности информации. </p>
        <p className='services-description section-subtitle-color'>Интеграция с сервисом ТУНДУК:</p>
        <p className='services-description'>Интеграция вашего сайта или приложения с сервисом ТУНДУК для обеспечения актуальности и верности запрашиваемых данных 
Создание сервиса для получения и обработки данных посредствам сервисов ТУНДУК. 
Обеспечение безопасности и защиты данных клиентов в процессе использования.</p> */}
       
        <div className='services-row'>
          {services && services.map(service => (
            <div className='services-card' key={service.id}>
              <div className='card-icon'><DynamicFaIcon name={service.icons} /></div>
              <p className='services-card-title'>{service.name}</p>
              <p className='services-card-text'>{service.description}</p>
            </div>
          ))}
        </div>
    </div>
  )
}
