import React from "react";
import "./Section.css";
import sectionImg from "../img/sectionImg.webp";

export default function Section() {
  return (
    <div className="section" id="section">
      <div className="section-description">
        <h1>Key Dev</h1>
        <h2>ВАШ МОСТ К УСПЕХУ</h2>
        <p>
          Высококвалифицированная команда, которая специализируется на
          предоставлении аутсорс услуг в области информационных технологий и
          разработке веб приложений
        </p>
        <a href="#contacts">
          <button>Связаться</button>
        </a>
      </div>
      <div className="section-img">
        <img
          src={sectionImg}
          alt="кейдев, KeyDev, КейДев, Разработка сайтов под ключ,  Разработка CRM систем, Создание уникального дизайна сайтов,
          Адаптивный пользовательский интерфейс, Интеграция CMS (систем управления контентом), Электронная коммерция (e-commerce),
          Оптимизация для поисковых систем (SEO), Безопасность и защита данных пользователей, Управление отношениями с клиентами (CRM),
          Интеграция CRM с существующими системами, Пользовательские панели управления и аналитика, IT-сопровождение,
          Мониторинг системы и устранение проблем, Обновление и модернизация системы, Резервное копирование данных,
          Интеграция с сервисом ТУНДУК, Получение и обработка данных через ТУНДУК, Безопасность данных клиентов" 
        />
      </div>
    </div>
  );
}
