import React, { useContext } from "react";
import axios from "axios";
import "./Employee.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from "../../context/auth";

// const baseURL = "http://127.0.0.1:8000/api/employee/";
const baseURL = "https://keydev.kg/api/employee/";

export default function Employee() {
  const [employees, setEmployees] = React.useState(null);
  const {token} = useContext(AuthContext)


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Token ${token}`,
        };
        const response = await axios.get(baseURL, { headers });
        setEmployees(response.data);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
      }
    };
  
    if (token) {
      fetchData();
    }
  }, [token]);
  

  const sliderSettings = {
    dots: false, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 6, 
    slidesToScroll: 2, 
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="team" id="team">
      <h1 className="section-title">НАША КОМАНДА</h1>
      <div className="team-items">
        <Slider {...sliderSettings}>
          {employees?.map((employee) => (
            <div className="team-item" key={employee.id}>
              <img
                src={employee.image}
                alt="кейдев, KeyDev, КейДев, Разработка сайтов под ключ,  Разработка CRM систем, Создание уникального дизайна сайтов,
                Адаптивный пользовательский интерфейс, Интеграция CMS (систем управления контентом), Электронная коммерция (e-commerce),
                Оптимизация для поисковых систем (SEO), Безопасность и защита данных пользователей, Управление отношениями с клиентами (CRM),
                Интеграция CRM с существующими системами, Пользовательские панели управления и аналитика, IT-сопровождение,
                Мониторинг системы и устранение проблем, Обновление и модернизация системы, Резервное копирование данных,
                Интеграция с сервисом ТУНДУК, Получение и обработка данных через ТУНДУК, Безопасность данных клиентов" 
              />
              <div className="team-item-info">
                <h3>
                  {employee.last_name} {employee.first_name}
                </h3>
                <h4>{employee.title}</h4>
              </div>
            </div>
          ))}
        </Slider>
        {/* {employees?.map((employee) => (
          <div className="team-item" key={employee.id}>
            <img
              src={employee.image}
              alt="кейдев, KeyDev, КейДев, Разработка сайтов под ключ,  Разработка CRM систем, Создание уникального дизайна сайтов,
    Адаптивный пользовательский интерфейс, Интеграция CMS (систем управления контентом), Электронная коммерция (e-commerce),
    Оптимизация для поисковых систем (SEO), Безопасность и защита данных пользователей, Управление отношениями с клиентами (CRM),
    Интеграция CRM с существующими системами, Пользовательские панели управления и аналитика, IT-сопровождение,
    Мониторинг системы и устранение проблем, Обновление и модернизация системы, Резервное копирование данных,
    Интеграция с сервисом ТУНДУК, Получение и обработка данных через ТУНДУК, Безопасность данных клиентов" 
            />
            <div className="team-item-info">
              <h3>
                {employee.last_name} {employee.first_name}
              </h3>
              <h4>{employee.title}</h4>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
}
