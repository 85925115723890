import React from 'react'
import './Footer.css'
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";


export default function Footer() {
  return (
    <div className='footer' id='footer'>
        <div className='footer-row'>
           <a href="https://www.instagram.com/key_dev.kg/" aria-label="KeyDev, КейДев, Разработка сайтов под ключ,  Разработка CRM систем, Создание уникального дизайна сайтов,
    Адаптивный пользовательский интерфейс, Интеграция CMS (систем управления контентом), Электронная коммерция (e-commerce),
    Оптимизация для поисковых систем (SEO), Безопасность и защита данных пользователей, Управление отношениями с клиентами (CRM),
    Интеграция CRM с существующими системами, Пользовательские панели управления и аналитика, IT-сопровождение"><FaInstagram/></a>
           <a href="#" aria-label="KeyDev, КейДев, Разработка сайтов под ключ,  Разработка CRM систем, Создание уникального дизайна сайтов,
    Адаптивный пользовательский интерфейс, Интеграция CMS (систем управления контентом), Электронная коммерция (e-commerce),
    Оптимизация для поисковых систем (SEO), Безопасность и защита данных пользователей, Управление отношениями с клиентами (CRM),
    Интеграция CRM с существующими системами, Пользовательские панели управления и аналитика, IT-сопровождение"><FiFacebook/></a>
        </div>
        <div className='footer-menu'>
            <a href="#section">Главная</a>
            <a href="#services">Услуги</a>
            <a href="#team">О Нас</a>
            <a href="#contacts">Контакты</a>
        </div>
        {/* <p className='footer-description'>Телефон: +996997226227</p> */}
        <p className='footer-description'>ОсОО "Кей Дев" © 2021</p>
    </div>
  )
}
