import React, { createContext, useState, useEffect, useContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        // const response = await fetch('http://127.0.0.1:8000/api/auth/token/', {
        const response = await fetch("https://keydev.kg/api/auth/token/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: "root",
            password: "root",
          }),
        });

        if (!response.ok) {
          console.error("Ошибка при запросе токена");
          return;
        }

        const data = await response.json();
        const accessToken = data.token;

        setToken(accessToken);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
      }
    };

    fetchToken();
  }, []);

  const contextValue = {
    token,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };
